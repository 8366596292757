/* body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

/* @import url('https://fonts.googleapis.com/css?family=Open+Sans'); */
@import url("https://fonts.googleapis.com/css?family=Roboto&display=swap");
/* @import url('https://fonts.googleapis.com/css?family=Montserrat&display=swap'); */

body {
  font-family: "Roboto", sans-serif;
  background-color: #212f41 !important;
  padding-top: 4.5rem;
}

.alert-pre {
  word-wrap: break-word;
  word-break: break-all;
  white-space: pre-wrap;
}

/* React Table Classes */
.ReactTable .rt-thead .rt-th.-sort-asc {
  box-shadow: none !important;
}
.ReactTable .rt-thead .rt-th.-sort-asc::before {
  content: "\2191";
  position: absolute;
  right: 5px;
}

.ReactTable .rt-thead .rt-th.-sort-desc {
  box-shadow: none !important;
}
.ReactTable .rt-thead .rt-th.-sort-desc::before {
  content: "\2193";
  position: absolute;
  right: 5px;
}
